/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { MenuItem, Grid, ListItemText, Box, TextField } from "@material-ui/core";
import { connect, useSelector, shallowEqual } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Collapse from "@material-ui/core/Collapse";
import classNames from "classnames";
import theme from "../../../../AppTheme";
import AppDialogFullHeader from "../../common/AppDialogFullHeader";
import CardSelector from "../../common/CardSelector";
import DeployAppResources from "./DeployResources";
import {
  clearEnvURLs,
  fetchEnvURLsAndResources,
} from "../../../../actions/envsActions";
import {
  clearS3EnvURLs,
  fetchS3EnvURLs
} from "../../../../actions/s3EnvsActions";
import CardPlain from "../../../roles/CardPlain";
import SecurityGroupsSelector from "../SecurityGroups/SecurityGroupsSelector";
import { deployApplication } from "../../../../actions/applicationsActions";
import FilterMenuEnvironments from "../../../filters/FilterMenuEnvironments";
import NoResourcesDialog from "./NoResourcesDialog";
import { hasPermissions } from "../../../../utils/permissions-utils";
import { DEPLOYMENT_FLOWS } from "../../../../utils/constants";

const useStyles = makeStyles({
  modalContent: {
    minHeight: "50vh",
  },
  alignRight: {
    textAlign: "right",
  },
  buttonText: {
    backgroundColor: "none",
    fontSize: theme.typography.caption.fontSize,
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
});

const DeployMenuItem = ({
  application,
  environment,
  originEnvironment,
  onPress,
  auth,
  urlList,
  s3UrlList,
  ramList,
  cpuList,
  resourcesLoading,
  permissions,
  applyPermissions,
  getDeploymentResources,
  getS3DeploymentResources,
  clearDeploymentResources,
  clearS3DeploymentResources,
  deploy
}) => {
  const styles = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const initialTenantValue = useSelector(state => state.tenants.current?.code, shallowEqual)
  const tenantValue = useSelector(state => state.tenants.current?.locale?.code, shallowEqual)
  const currentTenant = initialTenantValue || tenantValue;
  const flows = [{ title: DEPLOYMENT_FLOWS.KUBERNETES, value: DEPLOYMENT_FLOWS.KUBERNETES }, { title: DEPLOYMENT_FLOWS.S3, value: DEPLOYMENT_FLOWS.S3 }]
  const [deploymentFlow, setDeploymentFlow] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState("settings");
  const [selectedS3Url, onSelectS3Url] = useState(s3UrlList ? s3UrlList[0] : "");

  const [selectedURL, setSelectedURL] = useState(urlList ? urlList[0] : "");
  const [selectedRam, setSelectedRam] = useState(ramList ? ramList[0] : "");
  const [selectedCpu, setSelectedCpu] = useState(cpuList ? cpuList[0] : "");
  const [selectedSecurityGroups, setSelectedSecurityGroups] = useState(
    application.securityGroups || []
  );

  const { ctry, access_token, id_token } = auth;

  useEffect(() => {
    if (environment && environment.locale && environment._id && openDialog) {
      getDeploymentResources({
        localeID: environment.locale,
        environmentID: environment._id,
        access_token,
        id_token,
      });
      getS3DeploymentResources({
        localeID: environment.locale,
        environmentID: environment._id,
        access_token,
        id_token,
      });
    }
  }, [environment, openDialog]);

  useEffect(() => {
    if (urlList && urlList.length) {
      setSelectedURL(urlList[0]._id);
    }
  }, [urlList]);

  useEffect(() => {
    if (s3UrlList && s3UrlList.length) {
      onSelectS3Url(s3UrlList[0]._id);
    }
  }, [s3UrlList]);

  useEffect(() => {
    if (ramList && ramList.length) {
      setSelectedRam(ramList[0].toString());
    }
  }, [ramList]);

  useEffect(() => {
    if (cpuList && cpuList.length) {
      setSelectedCpu(cpuList[0].toString());
    }
  }, [cpuList]);

  const hasResources = () =>
    urlList &&
    cpuList &&
    ramList &&
    urlList.length &&
    cpuList.length &&
    ramList.length;

  const securityGroupsVisible = () => {
    return (
      application.hasAuth &&
      hasPermissions(
        permissions || [],
        ["view_security_groups"],
        applyPermissions
      )
    );
  };

  const securityGroupsValid = () => {
    if (!application.hasAuth) {
      return true;
    }

    return selectedSecurityGroups.length;
  };

  const isInvalid = () =>
    (!selectedURL || !selectedRam || !selectedCpu || !securityGroupsValid()) && deploymentFlow === DEPLOYMENT_FLOWS.KUBERNETES;

  const onCancel = () => {
    setOpenDialog(false);
    setSelectedURL("");
    setSelectedRam("");
    setSelectedCpu("");
    setDeploymentFlow("");
    clearDeploymentResources();
    clearS3DeploymentResources();
  };

  const onDeploy = () => {
    if (isInvalid()) {
      return;
    }
    let environmentDeployedKeys;
    if (deploymentFlow === DEPLOYMENT_FLOWS.S3) {
      const selectedS3UrlData = s3UrlList.find(url => url._id === selectedS3Url)
      const { bucketName, isRoot } = selectedS3UrlData;
      environmentDeployedKeys = {
        s3Url: selectedS3Url,
        s3: {
          bucketName,
          isRoot
        }
      }
    } else {
      environmentDeployedKeys = {
        url: selectedURL,
        cpu: selectedCpu,
        ram: selectedRam,
      }
    }

    deploy({
      access_token,
      id_token,
      locale: ctry.toLowerCase(),
      body: {
        originEnvId: originEnvironment.id,
        environmentId: environment._id,
        application: {
          ...application,
          securityGroups: application.hasAuth ? selectedSecurityGroups : [],
        },
        coreAppJob: environment.deploymentInformation?.coreAppJob,
        ...environmentDeployedKeys
      },
    });
    onCancel();
  };

  const ActionButtons = () => {
    return (
      <>
        <Grid item xs="auto">
          <Button
            autoFocus
            color="default"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            disabled={isInvalid() || deploymentFlow === '' || (deploymentFlow === DEPLOYMENT_FLOWS.S3 && !s3UrlList?.length)}
            onClick={onDeploy}
          >
            Deploy
          </Button>
        </Grid>
      </>
    );
  };

  const formatUrl = (url) => {
    const lastChar = url.substr(-1)
    return lastChar === "/" ? url.slice(0, -1) : url
  }

  return (
    <>
      <MenuItem
        className={`MuiListItem-${environment.title.toLowerCase()}`}
        onClick={() => {
          onPress();
          setOpenDialog(true);
        }}
        data-testid={`PromoteTo${environment.title}_btn`}
      >
        <ListItemText secondary={`Promote to ${environment.title}`} />
      </MenuItem>

      <NoResourcesDialog
        environment={environment}
        isOpen={deploymentFlow === DEPLOYMENT_FLOWS.KUBERNETES && !hasResources() && !resourcesLoading}
        onConfirm={() => { setOpenDialog(false); setDeploymentFlow(""); }}
      />

      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        maxWidth="md"
      >
        <AppDialogFullHeader
          application={application}
          includeLogo
          buttons={<ActionButtons />}
        >
          <FilterMenuEnvironments
            envs={{ list: [environment] }}
            activeEnv={environment ? environment.title : ""}
            clearEnvironment={() => { }}
            setEnvironment={() => { }}
            skipAllOption
            isDisabled
          />
        </AppDialogFullHeader>
        <DialogContent className={styles.modalContent}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
              {!deploymentFlow && <CardSelector
                title="Select Deployment Flow"
                items={flows}
                onSelect={value => {
                  setDeploymentFlow(value);
                  if ((value === DEPLOYMENT_FLOWS.KUBERNETES && !hasResources() && !resourcesLoading)) setOpenDialog(false)
                }}
                isCardsCentered={true}
              />}
              {deploymentFlow === DEPLOYMENT_FLOWS.KUBERNETES && hasResources() && !resourcesLoading ? (<Fragment><Tabs
                value={selectedTab}
                onChange={(e, value) => setSelectedTab(value)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Settings" value="settings" />

                {securityGroupsVisible() && (
                  <Tab label="Security Groups" value="securityGroups" />
                )}
              </Tabs>
                <CardPlain
                  title={
                    selectedTab === "settings"
                      ? "Fill in the following fields"
                      : ""
                  }
                  isList
                >
                  <Grid item xs={12}>
                    <Collapse
                      in={selectedTab === "settings"}
                      key="settings-tab-content"
                    >
                      <DeployAppResources
                        selectedUrl={selectedURL}
                        selectedRam={selectedRam}
                        selectedCpu={selectedCpu}
                        onSelectUrl={value => setSelectedURL(value)}
                        onSelectRam={value => setSelectedRam(value)}
                        onSelectCpu={value => setSelectedCpu(value)}
                        urlList={urlList || []}
                        ramList={ramList || []}
                        cpuList={cpuList || []}
                        environment={environment}
                        application={application}
                      />
                    </Collapse>
                    <Collapse
                      in={selectedTab === "securityGroups"}
                      key="securityGroups-tab-content"
                    >
                      <SecurityGroupsSelector
                        environment={environment}
                        selectedSecurityGroups={selectedSecurityGroups}
                        onSelectSecurityGroups={value =>
                          setSelectedSecurityGroups(value)
                        }
                        onResetSecurityGroups={() =>
                          setSelectedSecurityGroups(application.securityGroups)
                        }
                      />
                    </Collapse>
                  </Grid>
                </CardPlain></Fragment>) : null}
              {deploymentFlow === DEPLOYMENT_FLOWS.S3 ? (<Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    select
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      },
                    }}
                    onChange={e => onSelectS3Url(e.target.value)}
                    label="Select S3 url"
                    value={selectedS3Url || ""}
                    variant="filled"
                    color="secondary"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classNames("MuiFilledInput-disableUnderline"),
                    }}
                  >
                    {s3UrlList?.map(
                      (item) =>
                        <MenuItem key={item._id} value={item._id}>
                          {item.isRoot ? item.value : `${formatUrl(item.value)}/${currentTenant}/${environment.type}/${application.title}`}
                        </MenuItem>
                    )}
                  </TextField>
                </Grid>
              </Grid>) : null}
              {deploymentFlow !== '' ?
                <Box mt={2}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs="auto">
                      <Button
                        autoFocus
                        color="default"
                        variant="outlined"
                        onClick={() => setDeploymentFlow('')}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    urlList:
      state.envs.envUrls?.urls?.filter(({ available }) => available) || null,
    s3UrlList:
      state.s3Envs.s3EnvUrls?.s3Urls || null,
    ramList: state.envs.envUrls?.availableResources?.ram || null,
    cpuList: state.envs.envUrls?.availableResources?.cpu || null,
    resourcesLoading: state.envs.loading,
    permissions: state.userPermissions.permissions,
    applyPermissions: state.userPermissions.enablePermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  getDeploymentResources: payload =>
    dispatch(fetchEnvURLsAndResources(payload)),
  getS3DeploymentResources: payload =>
    dispatch(fetchS3EnvURLs(payload)),
  clearDeploymentResources: () => dispatch(clearEnvURLs()),
  clearS3DeploymentResources: () => dispatch(clearS3EnvURLs()),
  deploy: payload => dispatch(deployApplication(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployMenuItem);
