/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import { Grid, MenuItem, TextField } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const DeployResources = ({
  selectedUrl,
  selectedRam,
  selectedCpu,
  urlList,
  ramList,
  cpuList,
  onSelectUrl,
  onSelectRam,
  onSelectCpu,
  environment,
  application
}) => {
  const applications = useSelector(state => state.applications, shallowEqual);
  console.log(application);
  console.log(environment);
  console.log(urlList)
  console.log(applications)
  console.log(applications.list)

  const [targetUrls, setTargetUrls] = useState([]);

 useEffect(() => {
    try{      		
      const targetEnvironmentId = environment._id;
      console.log(targetEnvironmentId)
      const targetApplicationId = application._id;
      console.log(targetApplicationId)
      const targetEnvironmentType = environment.type;
      console.log(targetEnvironmentType)
      const targetEnvironmentObject = applications?.list?.find(env => {
        const key = Object.keys(env)[0];
        console.log(key)
        return key === targetEnvironmentType
      })
      const targetEnvironment = targetEnvironmentObject[targetEnvironmentType];
      console.log(targetEnvironment)
      const targetEnvironmentUrls = targetEnvironment?.urls;
      const targetApplication = targetEnvironment?.data?.find(app => app?.application === targetApplicationId);
      console.log(targetApplication)
      const applicationUrlId = targetApplication?.url;
      console.log(applicationUrlId)
      const applicationUrl = targetEnvironmentUrls.find(url => url?._id == applicationUrlId);
      console.log(applicationUrl)
      if(applicationUrl) return setTargetUrls([...urlList, applicationUrl]);
      setTargetUrls(urlList);
    }catch(error){
      console.log(error);
      setTargetUrls(urlList);
    }
 }, [environment, application, urlList, applications]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectUrl(e.target.value)}
          label="Select url"
          value={selectedUrl || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {targetUrls.map(
            url => (
                <MenuItem key={url._id} value={url._id}>
                  {url.value}
                </MenuItem>
              )
          )}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectCpu(e.target.value)}
          label="Select CPU"
          value={selectedCpu || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {cpuList.map(cpu => (
            <MenuItem key={cpu} value={cpu}>
              {`${cpu} m`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectRam(e.target.value)}
          label="Select RAM"
          value={selectedRam || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {ramList.map(ram => (
            <MenuItem key={ram} value={ram}>
              {`${ram} mi`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default React.memo(DeployResources);
